td {
  display: flex;
  align-items: center;
  padding: 0;
}

td:hover {
  background: #f9fafb;
  cursor: pointer;
}

/* Remove hover color if child is in focus  */
td:focus-within {
  /* background: #fcf8e1; */
  cursor: text;
}

.content {
  position: relative;
  width: "100%";
}

.check-box {
  display: "block";
  margin-right: ".78571429em";
  padding: ".78571429em" !important;
}

.completed {
  background: #32b64a;
  color: white;
}

.completed:hover {
  background: #32b64a;
}

.contenteditable-style {
  width: "100%";
  position: "relative";
  margin-left: ".78571429em";
  outline: 0;
}
